// Thin (Hairline) 200
// Light 300
// Regular 400
// Medium 500
// Semibold 600
// Bold 700
// Extra bold 800
// Black 900

// ################################ Lato ################################
// Thin (Hairline)
@font-face {
  font-family: $font-lato;
  src: url(./fonts/Lato-Thin.ttf) format("truetype");
  font-weight: 200;
}

// Light
@font-face {
  font-family: $font-lato;
  src: url(./fonts/Lato-Light.ttf) format("truetype");
  font-weight: 300;
}

// Regular
@font-face {
  font-family: $font-lato;
  src: url(./fonts/Lato-Regular.ttf) format("truetype");
  font-weight: 500;
}

// Bold
@font-face {
  font-family: $font-lato;
  src: url(./fonts/Lato-Bold.ttf) format("truetype");
  font-weight: 700;
}

// Black
@font-face {
  font-family: $font-lato;
  src: url(./fonts/Lato-Black.ttf) format("truetype");
  font-weight: 900;
}

// ################################ Raleway ################################
// Light
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-Light.ttf) format("truetype");
  font-weight: 300;
}

// Regular
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-Regular.ttf) format("truetype");
  font-weight: 400;
}

// Medium
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-Medium.ttf) format("truetype");
  font-weight: 500;
}

// Semi Bold
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

// Bold
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-Bold.ttf) format("truetype");
  font-weight: 700;
}

// Extra Bold
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}

// Black
@font-face {
  font-family: $font-raleway;
  src: url(./fonts/Raleway-Black.ttf) format("truetype");
  font-weight: 900;
}
