// ############################################### Font family
$font-montserrat: "Montserrat"; // Primary Unused
$font-comfortaa: "Comfortaa"; // Secondary Unused

$font-raleway: "Raleway"; // Primary
$font-lato: "Lato"; // Secondary

// ############################################### Font sizes
// 1 rem === 16 px

// $font-size-h1: 2.25rem; // 36px; Unused
$font-size-h1: 4.56rem; // 80px;

// $font-size-h2: 2rem; // 32px; Unused
$font-size-h2: 2.75rem; // 44px;

$font-size-h3: 1.5rem; // 24px;
$font-size-h4: 1.5rem; // 24px;
$font-size-h5: 1.5rem; // 24px;
$font-size-h6: 1.125rem; // 18px;

// $font-size-p: 1.25rem; // 20px; Unused
$font-size-p: 1.5rem; // 24px;
