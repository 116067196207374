body,
input,
select,
textarea,
button {
  font-family: $font-lato, Helvetica, sans-serif;
}

// h1 {
//   font-family: $font-family-secondary;
//   font-size: $font-size-h1;
//   font-weight: 500;
//   font-style: normal;
//   color: $color-black;
//   line-height: 150%;

//   @media screen and (max-width: 708px) {
//     font-size: 40px;
//   }
//   @media screen and (max-width: 414px) {
//     font-size: 36px;
//   }
// }

// h2 {
//   font-family: $font-family-secondary;
//   font-style: normal;
//   font-weight: 500;
//   color: $color-black;
//   font-size: $font-size-h2 !important;
//   line-height: 150% !important;
//   margin-bottom: 0.5em !important;
// }

// h3 {
//   font-family: $font-family-secondary;
//   font-style: normal;
//   font-weight: 500;
//   color: $color-black;
//   font-size: $font-size-h3 !important;
//   line-height: 150% !important;

//   @media screen and (max-width: 414px) {
//     font-size: 28px;
//   }
// }

// h4 {
//   font-family: $font-family-primary;
//   font-size: $font-size-h4 !important;
//   font-weight: 500;
//   font-style: normal;
//   line-height: 150% !important;
//   color: $color-black;

//   @media screen and (max-width: 414px) {
//     font-size: 20px;
//   }
// }

// h5 {
//   font-family: $font-family-primary;
//   font-style: normal;
//   font-weight: 400;
//   font-size: $font-size-h5;
//   line-height: 150% !important;
//   color: $color-black;

//   @media screen and (max-width: 414px) {
//     font-size: 20px;
//   }
// }

// p {
//   font-family: $font-family-primary;
//   font-style: normal;
//   font-weight: normal;
//   font-size: $font-size-p;
//   line-height: 150%;
//   color: $color-black;

//   @media screen and (max-width: 414px) {
//     font-size: 16px;
//   }
// }
