* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}

html,
body {
  height: 100%;
  min-height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  // overflow-x: hidden;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}
// ::-webkit-scrollbar {
//   display: none;
// }

#root {
  margin: 0;
  height: 100%;
  width: 100%;
}

:focus {
  outline: none;
  transition: none;
}
::-moz-focus-inner {
  border: 0 !important;
}
