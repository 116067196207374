$baseurl: "";
@use "sass:math";
@import "reset", "colors", "variables", "typography", "fontface";
// @import "~bootstrap/scss/bootstrap";

// ############################################# FUNCTIONS ##################################################
// Resize the html


$breakpoints: (
  9999px: 16px,
  1020px: 16px,
  970px: 15.5px,
  920px: 15px,
  870px: 14.5px,
  820px: 14px,
  770px: 13.5px,
  720px: 13px,
  670px: 12.5px,
  620px: 12px,
  570px: 11.5px,
  520px: 11px,
  470px: 10.5px,
  420px: 10px,
  370px: 9.5px,
  320px: 9px,
);


@function parse-size($value) {
  $result: $value;

  @if (unitless($value)) {
    @return ($value / 16) * 1rem;
  }

  @if (unit($value) == "px") {
    $result: ($value / 16px); // Pixeles
    @return $result * 1rem;
  }

  @if (unit($value) == "rem") {
    $result: ($value / 1rem); // Rem
    @return $result * 1rem;
  }

  @return $value;
}

@function boundaries($min, $value, $max, $html-font-size: 16px) {
  @if (unitless($value)) {
    $value: $value * 1px;
  }

  // Convert into pixels
  @if (unit($value) == "rem") {
    $num: ($value / 1rem); // number without units
    $value: $html-font-size * $num;
  }

  @if (unitless($min)) {
    $min: $min * 1px;
  }

  // Convert into pixels
  @if (unit($min) == "rem") {
    $num: ($min / 1rem); // number without units
    $min: $html-font-size * $num;
  }

  @if (unitless($max)) {
    $max: $max * 1px;
  }

  // Convert into pixels
  @if (unit($max) == "rem") {
    $num: ($max / 1rem); // number without units
    $max: $html-font-size * $num;
  }

  $result: max($min, $value);
  $result: min($max, $result);
  @return $result;
}

@function quick-calc($x1, $x2, $exp: "+") {
  @if ($exp == "+") {
    @return $x1 + $x2;
  }

  @if ($exp == "-") {
    @return $x1 - $x2;
  }

  @if ($exp == "*") {
    @return $x1 * $x2;
  }

  @if ($exp == "/") {
    @return $x1 / $x2;
  }

  @return $x1 + $x2;
}

@each $width, $rem in $breakpoints {
  @media screen and (max-width: $width) {
    // ############################################# GENERALS ###################################################
    html {
      font-size: $rem; // Default 16px;
    }

    body {
      // background: $color-inowu-gradient;
      background-color: $color-athens-gray;
      overflow-y: scroll !important; /* Hide scrollbars */
      overflow-x: hidden !important;
    }

    button {
      border: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    a,
    .as-a {
      text-decoration: none;
      color: $color-primary;
      &:hover {
        color: $color-secondary;
      }
    }

    h1,
    .as-h1 {
      font-family: $font-raleway;
      font-size: parse-size($font-size-h1);
      font-weight: 500;
      font-style: normal;
      color: $color-primary;
      line-height: 115%;
      margin: 0px;
      margin-bottom: parse-size(25px);
      padding: 0px;
    }

    h2,
    .as-h2 {
      font-family: $font-raleway;
      font-style: normal;
      font-weight: 500;
      color: $color-primary;
      font-size: $font-size-h2;
      line-height: 125%;
      margin: 0px;
      margin-bottom: parse-size(40px);
      padding: 0px;
    }

    h3,
    .as-h3 {
      font-family: $font-raleway;
      font-style: normal;
      font-weight: 500;
      color: $color-black;
      font-size: $font-size-h3;
      line-height: 150%;
    }

    h4,
    .as-h4 {
      font-family: $font-raleway;
      font-size: $font-size-h4;
      font-weight: 500;
      font-style: normal;
      line-height: 150%;
      color: $color-black;
    }

    h5,
    .as-h5 {
      font-family: $font-raleway;
      font-style: normal;
      font-weight: 400;
      font-size: $font-size-h5;
      line-height: 150%;
      color: $color-black;
    }

    p,
    .as-p {
      font-family: $font-lato;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-p;
      line-height: 150%;
      color: $color-primary;
    }

    // ############################################# MAIN ELEMENT CLASSES ###################################################
    .container {
      width: 100%;
      max-width: 100% !important;
      padding: 0 parse-size(54px);
      margin: 0 auto;

      @media (max-width: 600px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    // ############################################# Header
    .header {
      // background: $gradient-header-shadow;
      position: fixed;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      height: boundaries(70px, parse-size(96px), parse-size(96px), $rem);
      padding: 0px parse-size(56px);
      margin: 0px 0px;
      z-index: 100;

      .header-background {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 1;
        background-image: $gradient-header-background;
        left: 0%;
        z-index: -1;
        transition: opacity 0.4s ease-in-out;

        &.off {
          opacity: 0;
          box-shadow: 0px 30px 60px rgba(32, 56, 85, 0.08);
        }
      }

      .header-logo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // width: auto;
        height: 100%;
        margin: 0px;
        padding-top: boundaries(16px, parse-size(24px), parse-size(24px), $rem);
        padding-bottom: boundaries(
          16px,
          parse-size(24px),
          parse-size(24px),
          $rem
        );

        img {
          // min-width: 140px;
          height: 100%;
        }
      }

      .header-links-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin: 0px;
        margin-left: auto;
        margin-bottom: parse-size(7px);
        padding: 0px;

        .header-links {
          color: #003a54;
          font-size: $font-size-p; // 20px
          font-family: $font-lato;
          font-weight: bold;
          margin: parse-size(28px) parse-size(10px);
          padding: 0px;
          text-align: center;

          &.white {
            color: white;
          }

          &:first-child {
            margin-left: 0px;
          }
          @media (max-width: 600px) {
            &:first-child {
              visibility: hidden;
            }
          }

          &:last-child {
            margin-right: 4%;
          }
        }
      }
    }

    // ############################################# Screen Title
    .screen-title {
      width: 100%;
      // height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      white-space: pre-line;
      margin: 0px 1rem;

      .screen-title-contents {
        padding: 0px 90px;

        .screen-title-head {
          background-image: none;
        }

        .screen-title-body {
          // margin-bottom: parse-size(46px);
          background-image: none;
        }
      }

      .screen-title-bg {
        object-fit: cover;
        height: 100%;
        width: 100%;
        z-index: -1;
        position: absolute;
        top: 0%;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      white-space: pre-line;

      .title-main {
        background-image: none;
        font-variant: normal;
        margin-bottom: 0px;
      }

      .title-head {
        display: flex;
        flex-direction: column;
        white-space: pre-line;
        font-size: 44px;
        margin: 0px 1rem;
      }

      .title-body {
        margin-bottom: parse-size(48px);
      }

      .title-link {
        // margin-top: 16px;
        background-image: none;
      }
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      white-space: pre-line;
      margin: 0px 1rem;

      .subtitle-head {
        &.shrink {
          margin-bottom: parse-size(24px);
        }
      }

      .subtitle-body {
        margin-bottom: parse-size(40px);

        &.shrink {
          margin-bottom: parse-size(24px);
        }
      }

      .subtitle-link {
        font-size: parse-size(28px);

        &.shrink {
          font-size: parse-size(22px);
        }
      }
    }

    // ########################## Complements and special displays

    .card-display {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: parse-size(32px);
      margin-bottom: parse-size(24px);
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
    }

    .card-principal {
      width: parse-size(400px); // 400px
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: boundaries(1px, parse-size(4px), parse-size(4px), $rem);

      @media screen and (max-width: "720px") {
        margin-left: 32px;
        margin-right: 32px;
      }
    }

    .video-description {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: parse-size(32px) 0px;

      .video-description-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-left: parse-size(50px);

        a {
          align-self: flex-end;
        }
      }

      .video-player-wrapper {
        flex-grow: 1;
        padding: 0px;
        max-width: parse-size(786px);
        max-height: parse-size(411px);

        .react-player {
          position: relative;
          aspect-ratio: 16/9;
        }

        .react-player > div {
          position: absolute;
        }
      }
    }

    .items-list {
      // margin-top: parse-size(32px);
      // margin-bottom: parse-size(32px);

      .items-list-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        // align-items: center;
        align-items: flex-start;
        // align-content: flex-start;
        align-content: center;
      }

      .list-item {
        display: flex !important;
        flex-wrap: nowrap;
        flex-direction: row;
        // align-items: center;
        align-items: flex-start;
        align-self: baseline;
        justify-content: right;
        margin-top: 4px;
        margin-bottom: 4px;

        h5 {
          margin-bottom: 0px;
        }
      }

      .slick-track {
        display: flex !important;
      }

      .slick-slide {
        height: inherit !important;
        display: flex !important;
      }
      .slick-slide > div {
        height: 100%;
        display: flex !important;
      }

      &.x-center {
        .slick-slide > div {
          justify-content: center;
        }
      }

      &.y-center {
        .slick-slide > div {
          align-items: center;
        }
      }

      &.x-right {
        .items-list-container > .list-item {
          margin-left: auto;
        }

        .slick-slide > div {
          justify-content: right;
        }
      }

      &.y-end {
        .slick-slide > div {
          align-items: flex-end;
        }
      }

      &.x-left {
        .items-list-container > .list-item {
          margin-right: auto;
        }

        .slick-slide > div {
          justify-content: left;
        }
      }

      &.y-start {
        .slick-slide > div {
          align-items: flex-start;
        }
      }
    }

    .quotes-container {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .quote {
      display: flex;
      flex-direction: row;
      margin-top: 50px;
      margin-bottom: 50px;

      .text-area {
        max-width: 1012px;

        h5 {
          display: flex;
          align-items: center;

          a {
            display: flex;
            align-items: center;
          }
        }
      }

      .author {
        border-radius: 50px;
        margin-right: 17px;
        aspect-ratio: 1/1;
      }

      .social-link {
        margin-left: 11px;
        filter: brightness(0.5);
      }
    }

    .quote.inverted {
      display: flex;
      flex-direction: row-reverse;

      .author {
        border-radius: 50px;
        margin-left: 17px;
      }

      .text-area {
        text-align: end;

        h5 {
          display: inline-block;
          a {
            display: inline-block;
          }
        }
      }

      .social-link {
        margin-right: 10px;
      }
    }

    .app-grid {
      display: flex;
      width: auto;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .app-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .app-row {
        width: 100%;
        justify-content: space-between;
        margin-left: parse-size(45px);
        margin-right: parse-size(45px);
      }

      .grid-item-wrapper {
        margin-left: parse-size(20px);
        margin-right: parse-size(20px);
      }

      .app-grid-item {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: parse-size(75px);

        .app-icon {
          //max-width: parse-size(100px);
          height: parse-size(100px);
          object-fit: contain;
        }
      }
    }

    .band {
      // background-color: $color-primary;
      display: flow-root;
      box-shadow: 0px 30px 60px rgba(32, 56, 85, 0.08);

      h3 {
        margin-bottom: parse-size(22px);
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      z-index: 1;
      align-items: stretch;
      background-color: $color-white;
      box-shadow: 0px -30px 60px rgba(32, 56, 85, 0.08);
      width: 100vw;
      height: max-content;
      height: clamp(100%, parse-size(200px), parse-size(200px));
      padding-bottom: parse-size(30px);

      .footer-section {
        display: flex;
        // width: 20%;
        width: 100%;
        height: 100%;
        flex-direction: column;
        white-space: pre-line;
        justify-content: flex-start;
        flex-grow: 1;
        padding-top: 0px;
        padding-top: clamp(
          parse-size(33px),
          parse-size(33px),
          parse-size(33px)
        );

        p {
          font-size: parse-size(20px);
          line-height: parse-size(44px);
          margin-bottom: 0px;
          min-width: 40%;
          justify-content: right;
        }

        &.big {
          flex-grow: 2;
          margin-top: 0px;
          padding-top: parse-size(33px);
        }

        .social-link {
          width: parse-size(35px);
          height: parse-size(35px);

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: $color-primary;
          border-radius: 50%;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);

          margin-left: parse-size(9px);
          margin-right: parse-size(9px);

          &:last-child {
            margin-right: 0px;
          }
          &:first-child {
            margin-left: 0px;
          }

          img {
            // width: 100%;
            height: 60%;
            // border-radius: 100%;
          }
        }

        .logo {
          position: relative;
          width: min-content;
          // top: parse-size(50px);
          // left: parse-size(75px);
          margin-left: auto;
          margin-right: auto;

          img {
            width: auto;
            height: parse-size(48px);
          }
        }
      }
    }

    .contact-form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-left: 5%;
      flex-grow: 1;
      min-width: 50%;
      align-items: center;
    }

    .form-field {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: boundaries(3px, parse-size(6px), parse-size(6px), $rem);
        font-family: $font-lato;
        font-weight: 600;
        font-size: parse-size(17px);
      }

      input,
      textarea {
        background-color: $color-athens-gray;
        color: $color-primary;
        border: 2px solid rgba(62, 153, 203, 0.2);
        height: 45px;
        width: boundaries(48px, parse-size(450px), parse-size(450px), $rem);
        font-size: parse-size(20px);
        padding: boundaries(2px, parse-size(10px), parse-size(10px), $rem);
        margin-bottom: parse-size(24px);
        border-radius: 10px;
        font-family: $font-lato;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus-visible {
          // box-shadow: 0 0 0.25rem rgba(13, 110, 253, 0.25);
          border-color: $color-mint-morning;
        }

        &:invalid,
        &:required {
          box-shadow: 0 0 0.25rem rgba(231, 6, 6, 0.25);
          border-color: #e23333;
        }

        // &::placeholder {
        //   font-family: $font-lato;
        //   font-style: normal;
        //   font-weight: 500;
        //   font-size: 20px;
        //   line-height: 150%;

        //   color: black;

        //   opacity: 0.7;
        // }
        &.PhoneInputInput {
          margin-bottom: 0px;
        }
      }

      #description {
        width: parse-size(448px);
        height: parse-size(246px);
      }

      span.form-error {
        color: #e23333;
        opacity: 0.7;
        margin-left: parse-size(10px);
      }
    }
    .outSourcingRibbon {
      display: flex;
      float: right;
      padding: 1%;
      position: absolute;
      font-size: 15px;
      font-family: $font-lato;
      color: white;
      justify-content: center;
      font-weight: 600;
      background-color: #003a54;
      width: 110px;
      z-index: 20;
      margin-left: 71%;
      margin-top: -1%;
      border-radius: 12px 2.5px;
      transition: 0.3s;
    }
    @media (min-width: 650px) {
      .outSourcingRibbon {
        display: flex;
        float: right;
        padding: 1%;
        position: absolute;
        font-size: 15px;
        font-family: $font-lato;
        color: white;
        justify-content: center;
        font-weight: 600;
        background-color: #003a54;
        width: 110px;
        z-index: 20;
        margin-left: 81%;
        margin-top: -1%;
        border-radius: 15px 2.5px;
        transition: 0.3s;
      }
    }
    @media (min-width: 1500px) {
      .outSourcingRibbon {
        display: flex;
        float: right;
        padding: 1%;
        position: absolute;
        font-size: 25px;
        font-family: $font-lato;
        color: white;
        justify-content: center;
        font-weight: 600;
        background-color: #003a54;
        width: 195px;
        z-index: 20;
        margin-left: 70%;
        margin-top: -1%;
        border-radius: 20px 5px;
        transition: 0.3s;
      }
    }
    .overlay {
      position: fixed;
      background: rgba(99, 99, 99, 0.7);
      transition: opacity 500ms;
      visibility: visible;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      visibility: hidden;
    }

    .overlay:target {
      visibility: visible;
      opacity: 1;
    }
    .popup .close {
      position: absolute;
      justify-content: right;
      top: 0px;
      right: 0px;
      transition: all 200ms;
      font-size: 35px;
      font-weight: bold;
      margin-top: -2.5%;
      text-decoration: none;
      color: #333;
    }
    @media (min-width: 650px) {
      .popup .close {
        position: absolute;
        justify-content: right;
        top: 0px;
        right: 0px;
        transition: all 200ms;
        font-size: 35px;
        font-weight: bold;
        margin-right: 2%;
        margin-top: 2%;
        text-decoration: none;
        color: #333;
      }
    }
    .popup .close:hover {
      color: #06d85f;
    }

    .outSourcingRibbon:hover {
      width: 200px;
      transition: 0.3s;
      color: #dadada;
    }

    .Menu {
      margin: parse-size(12px) parse-size(32px);
      display: flex;
      flex-direction: column;
      white-space: pre-line;

      .Menu-item {
        list-style-type: none;
      }
    }

    .translate-menu {
      position: fixed;
      z-index: 999;
    }
    .translate-menu ul {
      position: fixed;
      bottom: 10%;
      right: 2%;
      padding: 6px;
      color: white;
      background-color: #026669;
      opacity: 0;
      border-radius: 15px;
      z-index: 0;
      transition: 0.3s;
      width: 95px;
      height: 75px;
      visibility: hidden;
      font-size: 15px;
    }
    @media (min-width: 800px) {
      .translate-menu ul {
        position: fixed;
        bottom: 14.5%;
        right: 1.5%;
        padding: 2px;
        color: white;
        background-color: #026669;
        opacity: 0;
        border-radius: 15px;
        z-index: 0;
        transition: 0.3s;
        width: 100px;
        height: 70px;
        visibility: hidden;
      }
    }
    @media (min-width: 1200px) {
      .translate-menu ul {
        position: fixed;
        bottom: 9.5%;
        right: 1.5%;
        padding: 15px;
        color: white;
        background-color: #026669;
        opacity: 0;
        border-radius: 15px;
        z-index: 0;
        transition: 0.3s;
        width: 150px;
        height: 100px;
        visibility: hidden;
      }
    }
    .translate-menu.active ul {
      visibility: visible;
      opacity: 0.95;
      transition: 0.3s;
    }
    .translate-menu.deactive ul {
      visibility: hidden;
      transition: 0.3s;
      //opacity: 0;
    }
    .translate-menu ul li {
      font-weight: 500;
      list-style-type: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
    }
    @media (min-width: 1200px) {
      .translate-menu ul li {
        font-size: 22px;
        padding: 1%;
      }
    }
    .translate-menu ul li:last-child {
      border-bottom: none;
    }
    .translate-menu ul li:hover {
      font-weight: 720;
      cursor: pointer;
    }

    .displayer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      // height: 22rem;
      height: max-content;
      width: 100%;
      margin: 0px;
      padding: 0px;
      //@at-root
      //color:#002c2e;

      &.in-column {
        flex-direction: column;
      }

      .displayer-controls-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: auto;
        align-items: center;
        justify-content: flex-start;
        z-index: 10;

        &.in-column {
          flex-direction: row;
          justify-content: center;
        }

        .displayer-controls {
          height: 40px;
          width: 40px;
          border-radius: 100%;
          border: 1px solid #99b0bb;
          background-color: white;
          font-size: 20px;
          font-family: "Lato", sans-serif;
          font-weight: bold;
          text-align: center;
          opacity: 0.5;
          color: #bfced5;
          margin-left: 4.68rem;
          margin-bottom: 1.5rem;
          margin-top: 0px;
          margin-right: 3.43rem;

          &.in-column {
            margin: 0px;
            margin: parse-size(12px) parse-size(24px);
          }

          transition-property: opacity, background-color, color;
          transition-duration: 0.2s;
          transition-timing-function: ease-in;
          -moz-transition-property: opacity, background-color, color;
          -moz-transition-duration: 0.2s;
          -moz-transition-timing-function: ease-in;
          -webkit-transition-property: opacity, background-color, color;
          -webkit-transition-duration: 0.2s;
          -webkit-transition-timing-function: ease-in;
          -o-transition-property: opacity, background-color, color;
          -o-transition-duration: 0.2s;
          -o-transition-timing-function: ease-in;

          &:hover {
            opacity: 0.7;
          }

          &.selected {
            background-color: #00e6be;
            color: white;
            border: none;
            opacity: 1;
          }
        }
      }

      .displayer-screens-container {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: auto;
        width: auto;
        align-items: center;
        justify-content: center;

        .displayer-screens {
          position: absolute;
          width: 100%;
          height: 100%;

          // @if opacity = 0 {
          //   visibility: hidden;
          // }
          opacity: 0;

          transition-property: opacity;
          transition-duration: 0.2s;
          transition-timing-function: ease-in;
          -moz-transition-property: opacity;
          -moz-transition-duration: 0.2s;
          -moz-transition-timing-function: ease-in;
          -webkit-transition-property: opacity;
          -webkit-transition-duration: 0.2s;
          -webkit-transition-timing-function: ease-in;
          -o-transition-property: opacity;
          -o-transition-duration: 0.2s;
          -o-transition-timing-function: ease-in;

          &.selected {
            opacity: 1;
            visibility: visible;
            position: relative;
          }
        }
      }
    }

    .scroll-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: boundaries(36px, parse-size(56px), parse-size(56px), $rem);
      height: boundaries(36px, parse-size(56px), parse-size(56px), $rem);
      border: none;
      border-radius: 100%;
      font-size: boundaries(12px, parse-size(24px), parse-size(24px), $rem);
      text-align: center;

      img {
        width: 40%;
        height: auto;
      }
    }

    .custom-slider {
      display: flex;
      flex-direction: column;

      .custom-slider-control {
        height: boundaries(22px, parse-size(34px), parse-size(34px), $rem);
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;

        .custom-slider-track {
          &.custom-slider-track-0 {
            border-bottom: $color-secondary solid
              boundaries(2px, parse-size(4px), parse-size(4px), $rem);
          }

          &.custom-slider-track-1 {
            border-bottom: $color-secondary-off dashed
              boundaries(2px, parse-size(4px), parse-size(4px), $rem);
          }
        }

        .custom-slider-thumb {
          height: boundaries(22px, parse-size(34px), parse-size(34px), $rem);
          width: boundaries(22px, parse-size(34px), parse-size(34px), $rem);
          border-radius: 50%;
          border: $color-secondary solid 1px;
          background-color: transparent;

          display: flex;
          align-items: center;
          justify-content: center;

          position: relative;

          .custom-slider-control-bar {
            position: absolute;
            height: 0px;
            border-bottom: $color-secondary solid
              boundaries(2px, parse-size(4px), parse-size(4px), $rem);
            left: 100%;
          }
        }

        .custom-slider-mark {
          margin-left: boundaries(5px, parse-size(7px), parse-size(7px), $rem);
          width: boundaries(12px, parse-size(20px), parse-size(20px), $rem);
          height: boundaries(12px, parse-size(20px), parse-size(20px), $rem);
          background-color: $color-secondary-off;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid $color-secondary;
          background-color: $color-white;

          &.on {
            border: none;
            background-color: $color-secondary;
          }
        }
      }

      .custom-slider-screen {
        overflow: hidden;
        margin-top: parse-size(12px);

        .custom-slider-band {
          white-space: nowrap;
          transition: transform 0.4s ease;

          .custom-slider-item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            white-space: normal;
            margin: 0px parse-size(24px);
            opacity: 0.4;

            &:not(.active) {
              * {
                color: $color-primary !important;
              }
            }

            &:first-child {
              margin-left: 0px;
            }

            &:last-child {
              margin-right: 0px;
            }

            &.active {
              opacity: 1;
            }
          }
        }
      }
    }

    // ########################## Misc

    .btn {
      font-family: $font-lato;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-p;
      line-height: 150%;
      text-align: center;
      padding: parse-size(12px) parse-size(32px);
      border-radius: 10px;
    }

    .breaker {
      position: relative;
      flex: none;

      .bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: auto;

        &.back-center {
          height: 100%;
          object-fit: cover;
          // object-position: center;
          // width: 100%;
        }
      }

      .top {
        bottom: 100%;
      }

      .bottom {
        top: 100%;
      }

      .bottom-in {
        bottom: 0%;
      }

      .top-in {
        top: 0%;
      }
    }

    // .translate-button {
    //   height: 64px;
    //   width: 64px;
    //   border-radius: 32px;
    //   position: fixed;

    //   z-index: 5;
    //   right: 3.15rem; // 50px; // left: 92.5%;
    //   bottom: 2.15rem; // 34px; // top: 87%;

    //   background-color: $color-primary;
    //   box-shadow: 0px 4px 4px rgba(100, 230, 14, 0.25);
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;

    //   button {
    //     background-color: transparent;
    //     color: transparent;
    //     border: none;
    //     height: 100%;
    //     width: 100%;
    //     background-repeat: no-repeat;
    //     background-position: center;

    //     img {
    //       position: absolute;
    //     }

    //     span {
    //       font-family: $font-lato;
    //       color: $color-secondary;
    //       position: relative;
    //       font-size: 18px;
    //       width: min-content;
    //       left: 42%;
    //       text-shadow: black 2px 3px 8px;
    //       top: 24%;
    //       font-weight: 500;
    //     }
    //   }
    // }

    .break-lines {
      white-space: pre-line;
    }

    .as-label {
      opacity: 0.7;
      color: $color-black;
    }

    .as-icon {
      opacity: 0.5;
      filter: grayscale(1);
      font-family: $font-lato;
    }

    .raleway-font {
      font-family: "Raleway", sans-serif;
    }

    .lato-font {
      font-family: "Lato", sans-serif;
    }

    // Custom links and diferent transitions
    .custom-link::before {
      border-bottom-width: quick-calc(1em, 7, "/");
    }

    .pp {
      border-bottom-width: quick-calc(1em, 7, "/");
    }

    // .calendlyPopup {
    //   box-shadow: 1px 1px 3px 3px rgb(110, 110, 110);
    // }

    //   @media (min-width: 1200px) {
    //     .calendlyPopup {
    //       color: white;
    //       display: flex;
    //       flex-direction: column;
    //       border-radius:10px;
    //       padding:15px;
    //       width: 350px;
    //       min-height:30px;
    //       min-width: 120px;
    //       //margin-left: 5%;
    //       font-weight: 600;
    //       font-size: 44px;
    //       background-color: #11ad7e;
    //       box-shadow: 1px 1px 3px 3px rgb(110, 110, 110);
    //     }
    //   }

    .calendlyPopup:hover {
      color: rgb(245, 245, 245);
      box-shadow: 1px 1px 5px 5px rgb(110, 110, 110);
    }

    .custom-link {
      color: $color-secondary;
      background: none;
      font-weight: 900;
      border: none;
      border-bottom: quick-calc(1em, 7, "/") solid $color-secondary;
      border-bottom-width: quick-calc(1em, 7, "/");
      border-bottom-width: clamp(2px, quick-calc(1em, 7, "/"), 7px);
      border-bottom-style: solid;
      border-bottom-color: $color-secondary;
      padding: 0px;

      transition: padding 0.3s ease-out, border-bottom-width 0.3s ease-out;

      $rem-1: 0.8em; // quick-calc(1em, 7, "/");
      $rem-3: 1.6em; // quick-calc(3em, 7, "/");
      $rem-5: 2.4em; // quick-calc(5em, 7, "/");

      &.grow {
        &.both {
          padding: 0px $rem-1;
          padding: 0px clamp(2px, $rem-1, $rem-1);
          &:hover {
            padding: 0px $rem-3;
            padding: 0px clamp(2px, $rem-3, $rem-3);
          }
        }

        &.right:hover {
          padding-right: $rem-5;
          padding-right: clamp(2px, $rem-5, $rem-5);
        }

        &.left:hover {
          padding-left: $rem-5;
          padding-left: clamp(2px, $rem-5, $rem-5);
        }
      }

      &.shrink {
        &.both {
          padding: 0px $rem-3;
          padding: 0px clamp(2px, $rem-3, $rem-3);
          &:hover {
            padding: 0px 0px;
          }
        }

        &.right {
          padding-right: $rem-5;
          padding-right: clamp(2px, $rem-5, $rem-5);
          &:hover {
            padding-right: 0px;
          }
        }

        &.left {
          padding-left: $rem-5;
          padding-left: clamp(2px, $rem-5, $rem-5);

          &:hover {
            padding-left: 0px;
          }
        }
      }

      &.increase:hover {
        border-bottom-width: 7px;
        border-bottom-width: clamp(2px, $rem-3, 7px);
      }

      &.decrease {
        border-bottom-width: 7px;
        border-bottom-width: clamp(2px, $rem-3, 7px);
        &:hover {
          border-bottom-width: 2px;
          border-bottom-width: clamp(2px, $rem-1, 7px);
        }
      }
    }

    .no-selectable {
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
    }

    .no-selectable-all {
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;

      * {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -o-user-select: none;
      }
    }

    .image-holder {
      position: relative;
      pointer-events: none;

      .image-holder-canvas {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: max-content;
        pointer-events: none;

        img {
          position: relative;
          pointer-events: none;
        }
      }
    }

    .click-through {
      pointer-events: none;
    }

    .z-index-auto {
      z-index: inherit;

      * {
        z-index: inherit;
      }
    }

    .no-background {
      background: none;

      * {
        background: none;
      }
    }

    #gradient-canvas {
      width: 100%;
      height: 100%;
      --gradient-color-1: #00e6eb;
      --gradient-color-2: #49d198;
      --gradient-color-3: #11ad7e;
      --gradient-color-4: #003a54;
    }

    #gradient-canvas.cant-load {
      background-image: url("./images/bg/about.png");
      background-position: center bottom;
      visibility: hidden;
    }

    .screen {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: flex-start;
      // height: 100%;
      // width: 100%;
      width: fit-content;
      position: relative;

      * > {
        flex: none;
      }
    }

    .reactive-container {
      position: relative;
      .reactive-container-object {
        position: absolute;
        transition: opacity 0.5s ease-in-out;
      }
    }

    // ############################################# AUXILIAR ELEMENT CLASSES ###################################################

    // ########################## Colors
    .black {
      color: $color-black;
    }
    .white {
      color: $color-white;
    }
    .gray {
      color: $color-gray;
    }

    .remark-primary {
      transition: color 0.3s ease-in-out;
      &:hover {
        color: $color-primary;
      }
    }

    .remark-secondary {
      transition: color 0.3s ease-in-out;
      &:hover {
        color: $color-secondary;
      }
    }

    // ########################## Margins
    .margin-left {
      margin-left: 50px;
    }
    .margin-right {
      margin-right: 50px;
    }

    .margin-h-20px {
      margin-left: 20px;
      margin-right: 20px;
    }
    .margin-h-12px {
      margin-left: 12px;
      margin-right: 12px;
    }

    .margin-bottom-30px {
      margin-bottom: 30px;
    }
    .margin-top-50px {
      margin-top: 50px;
    }

    .in-margin {
      margin-left: 50px;
      margin-right: 50px;
    }

    // ########################## Padding

    .padding-h-50px {
      padding-left: 50px;
      padding-right: 50px;
    }

    // ########################## Text formatting

    .text-align-center,
    .text-center {
      text-align: center;
    }
    .text-align-left,
    .text-left {
      text-align: left;
    }
    .text-align-right,
    .text-right {
      text-align: right;
    }

    .text-bold {
      font-weight: 700;
    }
    .text-normal {
      font-weight: 500;
      font-style: normal;
    }
    .text-italics {
      font-style: italic;
    }
    .text-thin {
      font-weight: 400;
    }
    // ########################## Displays & Flexbox utilities

    .flow-root {
      display: flow-root;
    }

    .flex,
    .flex-row {
      display: flex;
      flex-direction: row;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }
    .flex-nowrap {
      flex-wrap: nowrap;
    }

    .justify-end {
      justify-content: flex-end;
    }
    .justify-center {
      justify-content: center;
    }
    .justify-start {
      justify-content: flex-start;
    }
    .justify-around {
      justify-content: space-around;
    }
    .justify-between {
      justify-content: space-between;
    }

    .align-center {
      align-items: center;
    }
    .align-base {
      align-items: baseline;
    }

    .align-self-end {
      align-self: flex-end;
    }

    // ########################## Borders

    .border-2px {
      border-width: 2px;
    }

    // ########################## Images & Background

    .fit {
      width: fit-content;
      height: fit-content;
    }

    .background-white {
      background-color: $color-white;
    }

    .background-principal {
      background-color: $color-primary;
    }

    .background-fit {
      background-size: cover;
      background-position: center;
    }

    // ############################################# RESPONSIVE ###################################################

    @media screen and (max-width: 1020px) {
      .footer {
        flex-direction: column;
        justify-content: flex-start;

        .footer-section {
          .logo {
            top: 0px;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }

    @media screen and (max-width: 970px) {
      .contact-form {
        flex-direction: column;
        align-items: center;

        > div {
          margin: 0px !important;
        }
      }
    }

    @media screen and (max-width: 620px) {
      .title {
        align-items: center;
        text-align: center;
      }
    }

    @media screen and (max-width: 420px) {
      .header {
        padding: 0px 1rem;
      }
    }

    @media screen and (max-width: 380px) {
      .header {
        .header-logo {
          padding-top: boundaries(20px, 1.5rem, 1.5rem, $rem);
          padding-top: $rem;
          padding-bottom: boundaries(20px, 1.5rem, 1.5rem, $rem);
        }
      }

      .displayer {
        .displayer-controls-container {
          .displayer-controls {
            height: auto;
          }
        }
      }
    }
  }
}
.bills-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  div {
    width: 100%;
  }
  .logo-contain {
    display: flex;
    margin-top: 50px;
    margin-bottom: 30px;
    padding-left: 7%;
    background-color: white;
    position: relative;
    .logo {
      width: 60%;
      max-width: 600px;
      position: relative;
      z-index: 2;
    }
    .first-wave {
      position: absolute;
      height: 225px;
      width: 100%;
      left: 0;
      bottom: 0;
    }
    .first-wave::before {
      content: "";
      display: block;
      position: absolute;
      border-radius: 133% 80%;
      width: 196%;
      height: 88%;
      transform: translate(-38%, 61%);
      background-color: white;
    }
    // .first-wave::after{
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   border-radius: 100% 50%;
    //   width: 55%;
    //   height: 100%;
    //   transform: translate(-4%,40%);
    //   background-color: white;
    // }
  }
  .container-2 {
    display: flex;
    background-color: #edf6eb;
    padding-top: 80px;
    padding-bottom: 140px;
    padding-inline: 40px;
    .text {
      color: #5a626f;
      font-size: 32px;
      font-weight: 600;
    }
  }
  .container-3 {
    display: flex;
    background-color: white;
    padding-block: 50px;
    position: relative;
    .second-wave {
      position: absolute;
      height: 225px;
      width: 100%;
      left: 0;
      top: -170px;
    }
    .second-wave::before {
      content: "";
      display: block;
      position: absolute;
      border-radius: 94% 112%;
      width: 201%;
      height: 56%;
      transform: translate(-45%, 99%);
      background-color: #88c376;
    }
    img {
      height: 600px;
      width: 100%;
      object-fit: cover;
    }
  }
  .container-4 {
    display: flex;
    background-color: white;
    padding-top: 50px;
    padding-bottom: 20px;
    position: relative;
    color: #5a626f;
    .title {
      font-size: 32px;
      font-weight: 600;
      padding-left: 20px;
    }
    .third-wave {
      position: absolute;
      height: 225px;
      width: 100%;
      left: 0;
      top: -170px;
    }
    .third-wave::before {
      content: "";
      display: block;
      position: absolute;
      border-radius: 100% 91%;
      width: 357%;
      height: 68%;
      transform: translate(-1%, 35%);
      background-color: #88c376;
    }
  }
  .client-data {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .client-contain-1 {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      position: relative;
      align-items: flex-end;
      width: 100%;
      .video-gif {
        width: 70%;
        height: 500px;
        object-fit: cover;
        padding: 15px;
        border-radius: 40px;
      }
      .logo-image {
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        position: absolute;
        width: 50%;
        background-color: #f5f5f5;
        display: flex;
        min-height: 107px;
        max-height: 107px;
        padding-block: 15px;
        border-radius: 0 30px 30px 0;
        justify-content: center;
      }
    }
    .client-contain-2 {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      position: relative;
      align-items: flex-start;
      width: 100%;
      .video-gif {
        width: 70%;
        height: 500px;
        object-fit: cover;
        padding: 15px;
        border-radius: 40px;
      }
      .logo-image {
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        position: absolute;
        width: 50%;
        background-color: #0e0e0e;
        display: flex;
        min-height: 107px;
        max-height: 107px;
        padding-block: 15px;
        border-radius: 30px 0 0 30px;
        justify-content: center;
      }
    }
  }
  .container-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-block: 40px;
    .title {
      font-weight: 700;
      font-size: 16px;
      color: #5a626f;
    }
    .logo-gray {
      width: 55%;
    }
    .separate-1 {
      width: 80%;
    }
    .logo-container {
      margin-top: 20px;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      column-gap: 13%;
      row-gap: 10px;
      .image-logo {
        width: 100%;
      }
    }
    .separate-2 {
      width: 80%;
      transform: rotate(180deg);
    }
  }
  .container-6 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
    p {
      margin: 0;
    }
    .question {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .data-contain {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-inline: 30px;
        .input-contain {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .input-data {
            display: flex;
            gap: 20px;
            align-items: center;
            cursor: pointer;
            input {
              border: none;
              font-size: 14px;
              border-bottom: 1px solid #dddddd;
              width: 80%;
            }
            .input {
              width: 45px;
              height: 45px;
              background-color: rgba(209, 209, 209, 0.24);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              font-size: 20px;
              color: #dddddd;
            }
            .text-input {
              font-size: 14px;
              color: #878787;
            }
          }
        }
      }
      .input-text {
        border: none;
        font-size: 16px;
        color: #878787;
        border-bottom: 1px solid #878787;
      }
      .button-contain {
        display: flex;
        justify-content: center;
        button {
          padding-block: 15px;
          padding-inline: 20px;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
        .btn-1 {
          background-color: transparent;
          color: #878787;
          padding-inline: 30px;
          cursor: pointer;
        }
        .btn-2 {
          background-color: transparent;
          color: #878787;
          cursor: pointer;
        }
      }
    }
    .btn-contain {
      display: flex;
      justify-content: center;
      .send-btn {
        padding-block: 15px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        background-color: #5bac43;
        color: white;
        padding-inline: 60px;
        width: fit-content;
        cursor: pointer;
      }
    }
    .text-titles {
      font-size: 24px;
      color: #45a941;
      font-weight: 800;
    }
  }
}
