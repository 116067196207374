// ############################################### COLORS ###############################################

$color-inowu-gradient: linear-gradient(90deg, #00e6be 0%, #003a54 100%);
$color-primary: #003a54;
$color-secondary: #00e6be;
$color-secondary-off: #d6f0eb;
$color-auxiliar: #2e2e2e;

$color-white: #ffffff;
$color-black: #000000;
$color-gray: #2e2e2e;
$color-navy: #003a54;
$color-skyblue: #00e6be;
$color-alert: #dc3545;

$color-mercury: #e5e5e5;
$color-athens-gray: #f9fafb;
$color-mint-morning: #00e6be;

// ############################################### GRADIENTS ###############################################
$gradient-header-shadow: linear-gradient(
  180deg,
  #000000 -96.3%,
  rgba(0, 0, 0, 0) 100%
);

$gradient-header-background: linear-gradient(
  0deg,
  $color-white 0%,
  $color-white 100%
);

// ############################################### SHADOWS ###############################################
